body {
  height: 100%;
  font-family: VrodasFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  margin: 0;
  padding: 0;
}


/* @font-face {
  font-family: 'VrodasFont';
  src: local('VrodasFont'), url(assets/fonts/AkzidenzGrotesk-ExtraBold.otf) format('opentype');
}

@font-face {
  font-family: 'VrodasFont';
  src: local('VrodasFont'), url(assets/fonts/AkzidenzGrotesk-ExtraBoldItalic.otf) format('opentype');
}

@font-face {
  font-family: 'VrodasFont';
  font-weight: 300;
  src: local('VrodasFont'), url(assets/fonts/AkzidenzGrotesk-Light.otf) format('opentype');
}

@font-face {
  font-family: 'VrodasFont';
  font-weight: 300;
  src: local('VrodasFont'), url(assets/fonts/AkzidenzGrotesk-Italic.otf) format('opentype');
}

@font-face {
  font-family: 'VrodasFontCond';
  src: local('VrodasFontCond'), url(assets/fonts/AkzidenzGrotesk-BoldCond.otf) format('opentype');
}

@font-face {
  font-family: 'VrodasFontCond';
  src: local('VrodasFontCond'), url(assets/fonts/AkzidenzGrotesk-BoldCondItalic.otf) format('opentype');
}


@font-face {
  font-family: 'VrodasFontExtraCond';
  src: local('VrodasFontCond'), url(assets/fonts/AkzidenzGrotesk-ExtraBoldCond.otf) format('opentype');
}

@font-face {
  font-family: 'VrodasFontExtraCond';
  src: local('VrodasFontCond'), url(assets/fonts/AkzidenzGrotesk-ExtraBoldCondItalic.otf) format('opentype');
} */

/* @font-face {
  font-family: 'VrodasFontTitle';
  src: local('VrodasFontTitle'), url(assets/fonts/TC-NouvelleVague-Black.otf) format('OpenType');
} */

@font-face {
  font-family: 'VrodasFontTitle';
  src: local('VrodasFontTitle'), url(/public/static/assets/fonts/VRODAS.otf) format('OpenType');
}


@font-face {
  font-family: 'VrodasFont';
  src: local('VrodasFont'), url(/public/static/assets/fonts/Roc\ Grotesk\ W05\ Wide.ttf) format('TrueType');
}

@font-face {
  font-family: 'VrodasFont';
  src: local('VrodasFont'), url(/public/static/assets/fonts/Roc\ Grotesk\ W05\ Wide\ Bold.ttf) format('TrueType');
  font-weight: 500;
}

#root {
  height: 100vh;
  margin: 0;
  overflow: hidden;
  background-color: #0f071c;
  
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar,
#root::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}