.App {
  text-align: center;
  overflow: hidden;
	margin: 0;
	text-rendering: optimizeLegibility;
  background-color: #0f071c;
  color: #0f071c;
}

/* .rws-icons {
  grid-template-columns: repeat(5,1fr) !important;
} */

.rws-container {
  background-color: #0f071c !important;
  width: 100% !important;
}

.rws-container  header {
  color: #fff;
  font-family: "Open Sans" !important;
  font-size: 18px !important;
}

button.rws-close {
  background-color: #221e2b !important;
  color: #fff !important;
  font-family: "Open Sans" !important;
  text-transform: uppercase;
}